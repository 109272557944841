<template>
  <div>
    <page-title :heading="$t('accounting.lang_nav_invoiceDraft')" :subheading="$t('accounting.lang_nav_invoiceDraft')" :icon=icon show-previous-button ></page-title>

    <div class="app-main__inner">
      <EditDraftComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EditDraftComponent from "@/components/billing/drafts/EditDraftComponent";
import shiftMixin from "../../../mixins/pos/shiftMixin";

export default {
  name: "EditDraft",
  mixins:[shiftMixin],
  components: {EditDraftComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>